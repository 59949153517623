<template>
  <div class="create-apiKey">
    <CForm
      ref="createCollaborator"
      novalidate
      @submit.prevent="createCollaborator"
      class="create-action-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :submitTitle="'add'"
        :title="texts.createPage.title"
      />
      <!--   Content   -->
      <div class="form-content content">
        <div id="new-collaborator" v-show="userExist!=='default' && formData.email">
          <div v-if="userExist==='disabled'">
            <!--      ID      -->
            <ClFormRow :label="texts.createPage.idLabel" tool-tip-text="The id of a Collaborator">
              <template #content>
                <CInput
                  :value="formData.id"
                  add-input-classes="col-sm-12"
                  disabled="true"
                />
              </template>
            </ClFormRow>
            <!--     CREATED     -->
            <ClFormRow label="Created" tool-tip-text="Created">
              <template #content>
                <CInput
                  :value="created"
                  add-input-classes="col-sm-12"
                  disabled="true"
                />
              </template>
            </ClFormRow>
            <!--    SPACE NAME       -->
            <ClFormRow label="Space Name" tool-tip-text="The name of a Collaborator">
              <template #content>
                <CInput
                  :value="formData.spaceName"
                  add-input-classes="col-sm-12"
                  disabled="true"
                />
              </template>
            </ClFormRow>
          </div>
        </div>
        <!--    NAME    -->
<!--        <ClFormRow
                label="Name"
                :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
                placeholder="Name"
                v-model="formData.name"
                add-input-classes="col-sm-12"
                disabled="true"
            />
          </template>
        </ClFormRow>-->
        <!--    SURNAME    -->
<!--        <ClFormRow
                label="Surname"
                :toolTipText="descriptions.surname"
        >
          <template #content>
            <CInput
                placeholder="Surname"
                :value="formData.surname"
                add-input-classes="col-sm-12"
                disabled="true"
            />
          </template>
        </ClFormRow>-->
        <!--  EMAIL  -->
        <ClFormRow
          :label="texts.createPage.emailLabel"
          :toolTipText="descriptions.email"
        >
          <template #content>
            <CInput
              id="email"
              type="email"
              v-model="formData.email"
              add-input-classes="col-sm-12"
              required
              :placeholder="texts.createPage.emailPlaceholder"
              :is-valid="emailValidate"
              :invalidFeedback="texts.invalidFeedback"
            />
            <!-- <CButton @click="isUserExist(formData.email)" :disabled="loading" color="success">Check Email</CButton>-->
          </template>
        </ClFormRow>
        <!--  Collaborator role*  -->
<!--        <ClFormRow-->
<!--                label="Collaborator roles*"-->
<!--                :toolTipText="descriptions.collaboratorRole"-->
<!--        >-->
<!--          <template #content>-->
<!--            <multiselect-->
<!--                v-model="formData.roles"-->
<!--                :options="roleOptions"-->
<!--                :multiple="true"-->
<!--                :preserve-search="false"-->
<!--                :taggable="false"-->
<!--                :hide-selected="true"-->
<!--                :block-keys="['Tab', 'Enter']"-->
<!--                required-->
<!--                :is-valid="roleValidate"-->
<!--                invalidFeedback="Required"-->
<!--                :class="selectErrorClass"-->
<!--                :deselectLabel="selectLabel"-->
<!--                :selectLabel="selectLabel"-->
<!--                class="custom-multi-select zq--multiselect zq--multiselect__select"-->
<!--            ></multiselect>-->
<!--          </template>-->
<!--        </ClFormRow>-->
        <ClFormRow
          :label="texts.createPage.rolesLabel"
           :tooltipPosition="'hidden'"
          :toolTipText="descriptions.collaboratorRole"
        >
          <template #content>
            <ClRoleSelect
              :rolesData="rolesData"
              @checkRole="checkRole"
              required
              :is-valid="roleValidate"
            >
            </ClRoleSelect>
          </template>
        </ClFormRow>
        <!--    MODAL    -->
<!--        <Modal
            :modalShow="userExistModal"
            :messageGeneral="messageUserExist"
            :title="addCollabTitle"
            @doFunction="createCollaborator"
            v-on:toggle-modal="closeModal('add')"
        ></Modal>
        <Modal
            :modalShow="noUserModal"
            :messageGeneral="noUserMessage"
            :title="registerUserTitle"
            @doFunction="registerCollaborator"
            v-on:toggle-modal="closeModal('register')"
        ></Modal>-->
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
// import CMetaInputs from '@/components/forms/CMetaInputs';
// import {cloneDeep} from 'lodash';
// import Modal from '@/shared/UI/Modal';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import {formConfig, pageConfig} from "@/config";
import {dateFormate} from "@/utils";
import CreateHeader from "@/shared/components/CreateHeader";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClRoleSelect from "@/shared/components/formComponents/ClRoleSelect";
import {collaborators} from "@/config/descriptions/collaborators"
import {collaboratorRoles, administratorRoles, managerRoles, viewerRoles} from "@/config/settings/collaborators/roles";
import {collaboratorsTexts} from "@/config/pageTexts/collaborators";

export default {
  name: 'CreateCollaborator',
  components: {
    CreateHeader,
    ClFormRow,
    ClRoleSelect,
  },
  data() {
    return {
      descriptions: {
        ...collaborators.create
      },
      texts: {
        ...collaboratorsTexts
      },
      selectLabel: formConfig.selectLabel,
      formData: {
        email: '',
        roles: [],
        id: '',
        created: '',
        name: '',
        surname: '',
        spaceName: '',
      },
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      roleOptions: [],
      emailValidate: null,
      roleValidate: null,
      userExist: 'default',
      items: {},
      query: '',
      nameValidate: null,
      addCollabTitle: collaboratorsTexts.createPage.addCollabTitle,
      userExistModal: false,
      messageUserExist: collaboratorsTexts.createPage.messageUserExist,
      noUserModal: false,
      noUserMessage: collaboratorsTexts.createPage.noUserMessage,
      registerUserTitle: collaboratorsTexts.createPage.registerUserTitle,
      rolesData: [],
      checkedRolesData: {},
    };
  },
  computed: {
    ...mapGetters('collaborators', ['success', 'message', 'loading', 'collaborator', 'collaboratorsRoles']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';

      return {
        [className]: (this.roleValidate === false)
      }
    },
    created() {
      return dateFormate(this.formData.created);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.email) this.emailValidate = true;
      },
    },
    collaborator: {
      deep: true,
      handler: function () {
        // console.log('collaborator', item)
      }
    },
    collaboratorsRoles(item) {
      this.roleOptions = item;
      const customRolSets = ['Administrator', 'Manager', 'Viewer'];
      collaboratorRoles.forEach(roleData => {
        let title = roleData.title;
        let filteredRoles = roleData.roles.filter(role => {
          if (-1 !== customRolSets.indexOf(role)) {
            return true;
          }
          return this.roleOptions.includes(role);
        });

        if (filteredRoles.length) {
          let rolesObj = [];
          filteredRoles.forEach(role => {
            rolesObj.push({title: role, checked: false})
          })
          this.rolesData.push(
              {
                title: title,
                roles: rolesObj,
              }
          );
        }
      });
    }
  },
  methods: {
    ...mapActions('collaborators', [
      'handleCreateCollaborators',
      'handleGetAvailableRolesForCollaborators'
    ]),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);
      this.handleGetAvailableRolesForCollaborators();
    },
    /*checkUser() {
      this.roleValidate = !!this.formData.roles.length;
      this.roleValidate = Object.keys(this.checkedRolesData).length !== 0;
      this.emailValidate = !!this.formData.email.length;

      if (this.roleValidate && this.emailValidate) {
        if (this.userExist === 'disabled') {
          this.userExistModal = true;
        } else if (this.userExist === 'enabled') {
          this.noUserModal = true;
        }
      }
    },*/
    /*isUserExist(val) {
      this.query = val;
      this.handleGetCollaboratorByEmail(this.query)
        .then((items) => {
          if (items.length && items[0].email === this.formData.email) {
          this.formData = cloneDeep(items[0]);
          this.userExist = 'disabled';
        } else {
          this.formData.name = '';
          this.formData.surname = '';
          this.userExist = 'enabled';
        }
      });
    },*/
    /*registerCollaborator() {
      console.log("Register New User");
      this.noUserModal = false;
    },*/
    closeModal(val) {
      if (val === 'add') {
        this.userExistModal = false
      } else if (val === 'register') {
        this.noUserModal = false
      }
    },
    checkRole(data) {
      if (true === data.value) {
        if (!(data.roleGroup in this.checkedRolesData)) {
          this.checkedRolesData[data.roleGroup] = [];
        }
        this.checkedRolesData[data.roleGroup].push(data.roleName);
      } else {
        let index = this.checkedRolesData[data.roleGroup].indexOf(data.roleName);
        if (-1 !== index) {
          this.checkedRolesData[data.roleGroup].splice(index, 1);
        }
      }
      for (let key in data) {
        if (data[key].length) {
          this.roleValidate = true;
          return;
        }
      }
    },
    createCollaborator() {
      this.formData.roles = this.prepareRoles();
      this.roleValidate = !!this.formData.roles.length;
      this.emailValidate = !!this.formData.email.length;
      const body = [
        {
          email: this.formData.email,
          addRoles: this.formData.roles,
        },
      ];
      if (this.emailValidate && this.roleValidate) {
        this.handleCreateCollaborators({createCollaboratorRequestArray: body})
          .then(data => {
            if (data.length) {
              this.$router.push({
                name: 'PreviewCollaborator',
                params: {
                  id: data[0].externalReference,
                }
              })
            } else {
              console.log('Something went wrong');
            }
          });
      }
    },
    prepareRoles() {
      let roles = [];
      for (let key in this.checkedRolesData) {
        this.checkedRolesData[key].forEach(role => {
          if ('Administrator' === role) {
            roles = roles.concat(administratorRoles);
          } else if ('Manager' === role) {
            roles = roles.concat(managerRoles);
          } else if ('Viewer' === role) {
            roles = roles.concat(viewerRoles);
          } else {
            roles.push(role);
          }
        });
      }
      roles = roles.filter((value, index, self) => self.indexOf(value) === index);
      roles = roles.filter(role => {
        return this.roleOptions.includes(role);
      });
      if (roles.length) {
        this.roleValidate = true;
      }

      return roles;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss">
.create-apiKey {
  height: 100%;
  .create-action-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
      .custom-multi-select.zq--multiselect.zq--multiselect__select {
        .multiselect__select {
          opacity: 1;
        }
      }
    }
  }
  #published-que {
    top: 6px;
    right: -20px;
  }
}
</style>