export const collaboratorRoles = [
    {
        title: 'Custom',
        roles: ['Administrator', 'Manager', 'Viewer'],
    },
    {
        title: 'Full Access',
        roles: ['Owner', 'SuperAdmin'],
    },
    {
        title: 'Account',
        roles: ['ViewAccountSettings'],
    },
    {
        title: 'Achievements',
        roles: [
            'AdminAchievements',
            'ManageAchievements',
            'ViewAchievement',
        ],
    },
    {
        title: 'Actions',
        roles: [
            'AdminActions',
            'ViewActions',
        ],
    },
    {
        title: 'API',
        roles: ['AdminApi'],
    },
    {
        title: 'Awards',
        roles: ['ViewAwards'],
    },
    {
        title: 'Claim',
        roles: ['AdminClaim'],
    },
    {
        title: 'Collaborators',
        roles: ['AdminCollaborator'],
    },
    {
        title: 'Competitions',
        roles: [
            'ManageCompetition',
            'ViewCompetitions',
        ],
    },
    {
        title: 'Contests',
        roles: [
            'ManageContest',
            'ViewContest',
        ],
    },
    {
        title: 'Connections',
        roles: ['AdminConsumer'],
    },
    {
        title: 'Custom Fields',
        roles: [
            'AdminCustomFields',
            'ViewCustomFields',
        ],
    },
    {
        title: 'Events',
        roles: [
            'AdminEvents',
            'ViewEvents',
        ],
    },
    {
        title: 'Languages',
        roles: [
            'AdminLanguage',
            'ViewLanguage',
        ],
    },
    {
        title: 'Members',
        roles: [
            'AdminMembers',
            'ManageMembers',
            'ViewMembers',
        ],
    },
    {
        title: 'Messages',
        roles: [
            'AdminMessages',
            'ManageMessages',
            'ViewMessages',
        ],
    },
    {
        title: 'Products',
        roles: [
            'AdminProducts',
            'ManageProducts',
            'ViewProducts',
        ],
    },
    {
        title: 'Rewards',
        roles: [
            'AdminRewards',
            'ManageRewards',
            'ViewRewards',
        ],
    },
    {
        title: 'Reward Types',
        roles: [
            'AdminRewardType',
            'ManageRewardType',
            'ViewRewardType',
        ],
    },
    {
        title: 'Rules',
        roles: ['ViewRules'],
    },
    {
        title: 'Spaces',
        roles: [
            'AdminSpaces',
            'ViewSpaces',
        ],
    },
    {
        title: 'Tags',
        roles: [
            'AdminTags',
            'ManageTags',
            'ViewTags',
        ],
    },
    {
        title: 'Transformers',
        roles: ['AdminTransformers'],
    },
    {
        title: 'Units of Measure',
        roles: [
            'AdminUOM',
            'ManageUOM',
            'ViewUOM',
        ],
    },
    {
        title: 'Users',
        roles: [
            'AdminUser',
            'ViewUser',
        ],
    },
    {
        title: 'Webhooks',
        roles: ['AdminWebhooks'],
    },
    {
        title: 'Web Assets Repositories',
        roles: ['ManageFileObjectRep'],
    },
    {
        title: 'Web Assets Files',
        roles: [
            'AdminObjects',
            'ManageObjects',
            'ViewObjects',
        ],
    },
];

export const administratorRoles = [
    'SuperAdmin',
];

export const managerRoles = [
    'ViewAccountSettings',
    'AdminAchievements',
    'ManageAchievements',
    'ViewAchievement',
    'ViewActions',
    'ViewAwards',
    'AdminClaim',
    'ManageCompetition',
    'ViewCompetitions',
    'ManageContest',
    'ViewContest',
    'ViewEvents',
    'ViewLanguage',
    'AdminMembers',
    'ManageMembers',
    'ViewMembers',
    'AdminMessages',
    'ManageMessages',
    'ViewMessages',
    'AdminProducts',
    'ManageProducts',
    'ViewProducts',
    'AdminRewards',
    'ManageRewards',
    'ViewRewards',
    'ViewRules',
    'ViewRewardType',
    'ViewSpaces',
    'ViewTags',
    'ViewUOM',
    'AdminUser',
    'ViewUser',
    'ManageFileObjectRep',
    'AdminObjects',
    'ManageObjects',
    'ViewObjects',
    'ViewCustomFields',
];

export const viewerRoles = [
    'ViewAccountSettings',
    'ViewAchievement',
    'ViewActions',
    'ViewAwards',
    'ViewCompetitions',
    'ViewContest',
    'ViewCustomFields',
    'ViewEvents',
    'ViewLanguage',
    'ViewMessages',
    'ViewMembers',
    'ViewProducts',
    'ViewRewards',
    'ViewRewardType',
    'ViewRules',
    'ViewSpaces',
    'ViewTags',
    'ViewUOM',
    'ViewUser',
    'ViewObjects',
];



